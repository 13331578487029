import React from 'react';
import Row from '@Components/atoms/Row';
import { PropsWithClassName } from '@Components/helper';
import { iconSize, iconStroke } from '@Assets/styles/theme/theme';
import { Close, Root, StyledDivider, StyledHeadline, StyledIconX } from './Box.styles';
interface Props extends PropsWithClassName {
  children?: React.ReactNode;
  headline?: string | React.ReactNode | null;
  handleClose?: () => void;
}
const Box = (props: Props): React.ReactElement => {
  const {
    children,
    headline,
    handleClose,
    dataTestId
  } = props;
  const renderBoxHeader = () => {
    if (!headline && !handleClose) {
      return;
    }
    return <>
        <Row noGutters xsMiddle data-sentry-element="Row" data-sentry-source-file="Box.tsx">
          {headline && <StyledHeadline importance={4}>{headline}</StyledHeadline>}
          {handleClose && <Close data-test-id="box-close-button-root" onClick={() => handleClose()}>
              <StyledIconX size={iconSize} stroke={iconStroke} />
            </Close>}
        </Row>
        <StyledDivider data-sentry-element="StyledDivider" data-sentry-source-file="Box.tsx" />
      </>;
  };
  return <Root className={props.className} data-testid={dataTestId ? dataTestId : 'Box-root'} data-sentry-element="Root" data-sentry-component="Box" data-sentry-source-file="Box.tsx">
      {renderBoxHeader()}
      {children}
    </Root>;
};
export { Box };